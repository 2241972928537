import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Slider from "../../components/slider/slider";
import brain from "../../images/brain.jpg";
import tech from "../../images/tech.png";
import music from "../../images/music.png";
import joystick from "../../images/joystick.png";
import book from "../../images/book.jpg";

export default React.memo(function AboutMe({ setShift }) {
	const navigation = useNavigate();
	const [render, setRender] = useState(false);
	const [navText, setNavText] = useState();
	const [pos, setPos] = useState(0);
	const posts = [
		[
			brain,
			"I am a deep thinker. I get lost in the things I find fascinating, and I put a lot of effort into wrapping my head around them. People say you can think too much... I think they need to be a little more specific, because, while I think you can think too much about one thing, I never want to stop thinking about as many things as I can. My deep thinking stems from being a former compulsive perfectionist. I'm still a perfectionist, just not compulsively. I don't need to be a perfectionist, but, for something I really care about, I make sure to make a complete product.",
		],
		[
			tech,
			"I have a lot of fun with technology. I get a lot of joy out of being able to play around with some bit of code on a computer or do the wiring on some machine. I After taking a coding class for two years, I'd say I'm pretty good at coding. I don't know every language, but I have an intuitive understanding of programming. I think a step I'm wanting to take is to learn more about electronic components as well as computer science. I want to be able to know the inter workings of a computer like the back of my hand, then, I think, the real fun will begin.",
		],
		[
			music,
			"I have a deep appreciation for music. I want to be a musician, at least as a side to a different career, but I want to be a musician no matter what. I am currently learning to play the piano, have two synthesizers... a Korg Minilogue and a Arturia MicroFreak, and I'm learning to sing. Learning to sing has been a huge reality check for me in a way. Trying to sing so you can have a broad range and good sound, takes a good understanding of proper technique. As for me, however, I just want to be able to sing! So, naturally I experiment on how to hit high notes and use poor technique only to realize I'm hurting myself and actually do have to take the time to understand the larynx, soft pallete, etc. I've written a few songs, but they haven't made it to light yet. When I do ever write a song and master it, I'll let you know.",
		],
		[
			book,
			"I love writing and storytelling. I've had a history with writing. I've been told by my peers I'm pretty good at it, although I feel like every year it seems my writing skill from the year before was far inferior, so it's a little funny in hindsight. I've written concepts and short stories before, and I also have a lot of fun with campfire stories as well. You know why people shout Elmer, randomly in the woods. Me neither! And I'm not going to look it up, because I wrote a scary story that explains that which I told to my family. I also love movies and movie making. I made movies on a camcorder as a kid, played with 3d animation a lot as a kid, done edit spoofs, and made some animation, but nothing too significant YET. I have so many ideas for animations to make though, so I'm going to try to work in time in my routine to work on them.",
		],
	];
	useEffect(() => {
		if (window.location.pathname === "/") {
			setNavText(
				<>
					About
					<br />
					Me
				</>
			);
		}
		if (window.location.pathname === "/aboutMe") {
			setNavText("Home");
			setRender(true);
		}
		// sets up conditional rendering
	});
	function switchPost() {
		const point = Math.round(pos);
		const post =
			posts[Math.floor((point / 100) * posts.length)] ||
			posts[posts.length - 1];
		const postText = post[1].split(" ");
		return [
			postText
				.slice(0, Math.ceil(postText.length / 2))
				.reduce((a, c) => a + " " + c),
			postText.length > 1
				? postText
						.slice(Math.ceil(postText.length / 2, postText.length))
						.reduce((a, c) => a + " " + c)
				: "",
			post[0],
		];
	}
	return (
		<>
			<h1
				className="navSquare"
				id="aboutMeNav"
				onPointerDown={() => {
					if (window.location.pathname === "/") {
						navigation("/aboutMe");
						setShift("shift2F");
						document.addEventListener("animationend", startAboutMe);
						function startAboutMe(e) {
							if (e.animationName === "shift2F") {
								setShift("aboutMe");
								document.removeEventListener("animationend", startAboutMe);
							}
						}
						// styles change upon clicks, and certain things get changed after animations
					} else {
						navigation("/");
						setShift("shift2B");
						document.addEventListener("animationend", endAboutMe);
						function endAboutMe(e) {
							if (e.animationName === "shift2B") {
								setRender(false);
								document.removeEventListener("animationend", endAboutMe);
							}
						}
						// styles change upon clicks, and certain things get changed after animations
					}
				}}
			>
				{navText}
			</h1>
			{render ? (
				// conditional rendering
				<div className="aboutMe webPage">
					<Slider pos={pos} setPos={setPos} />
					<div className="info">
						<p>{switchPost()[0]}</p>
						<img src={switchPost()[2]} alt="image" />
						<p>{switchPost()[1]}</p>
					</div>
					{/* slider value changes the index of the posts array. */}
				</div>
			) : (
				""
			)}
		</>
	);
});
