import React from "react";

export default function Home() {
	return (
		// conditional rendering
		<div className="home webPage">
			<main>
				<h1>Maverick Oleson</h1>
				<br />
				<p>
					Hello and welcome. I am Maverick Oleson. This is my portfolio website.
					I coded it solely by myself in React JS. It presents my skillset,
					idealogies, career, and projects. Click any of the tiles to learn
					more.
				</p>
				<br />
				<p>
					If you're wondering more about what actually I did to make this, I
					made this in React JS along with a sass compiling program I wrote in
					Node to make a more organized component tree. Other technical aspects
					of this project include conditional rendering, responsive design, svgs
					design, color theory, css animations, and Three JS.
				</p>
				<br />
				<p>
					I also work in backend authentication and database managing with node,
					express, and vanilla javascript. I've used mongoDB and Google Firebase
					as databases for various projects. I also have my MTA Web Development
					Fundamentals certificate and my CIW Javascript Specialist certificate.
				</p>
				<br />
				<p>
					If you want to hire me for any front end web development work, or
					javascript work, feel free to contact me.
				</p>
				<br />
				<p>My email is: MaverickOleson@gmail.com</p>
				<br />
				<p>And my Github is: MaverickOleson</p>
				<br />
				<p>
					Also, here is the link for the github of this website, in case you
					want to take a look at the code:
				</p>
				<br />
				<p>https://github.com/MaverickOleson/Portfolio-Website</p>
			</main>
		</div>
	);
}
